.job-profile {
    .sidebar {
      width: 100%;
      height: 100%;
      background-color: white;
        .job-profile-sidebar {
          width: 260px;
          height: inherit;
          //background-color: $white;
          //border-top-left-radius: $card-border-radius;
          //border-bottom-left-radius: $card-border-radius;
          transition: all 0.3s ease, background 0s;
          z-index: 210;
          .job-profile-menu {
            width: 100%;
            height: 100%;
            z-index: 3;
            .compose-btn {
              padding: 1.5rem;
            }
            .sidebar-menu-list {
              position: relative;
              height: calc(100% - 80px); // search section height 80px
            }
          }
        }
        .list-group .list-group-item {
          padding: 0.58rem 0.5rem;
          border: 0;
          //font-weight: $font-weight-bold;
          letter-spacing: 0.4px;
          border-left: 2px solid transparent;
          border-radius: 0;
          + .list-group-item.active {
            margin-top: 0;
          }
          &:hover,
          &:focus,
          &.active {
            background: transparent;
            //color: $primary;
          }
          &.active {
            border-color: #7367f0;
          }
        }
    }
    // Right content area common css starts
    .content-right {
        border-left:solid 1px #ddd;
        width: calc(100% - 260px);
        .app-fixed-search {
          padding: 0.4215rem 0.5rem;
          border-bottom: 1px solid #ddd;
          background-color: white;
          border-top-right-radius: 0;
  
          .input-group:focus-within {
            box-shadow: none;
          }
          input,
          .input-group-text {
            border: 0;
            background-color: transparent;
          }
        }
    }
}
@media (max-width: 1025px ) {
    .job-profile {
        
          .sidebar-left {
            position: relative;
            .job-profile-sidebar {
              transform: translateX(-110%);
              transition: all 0.3s ease-in-out;
              position: absolute;
              left: 0;
              //border-top-left-radius: $border-radius-sm;
              //border-bottom-left-radius: $border-radius-sm;
            }
            &.show {
              .job-profile-sidebar {
                transform: translateX(0%);
                transition: all 0.3s ease;
              }
            }
          }
          .content-right {
            width: 100%;
            border-left: 0;
            .job-profile-details {
              width: 100%;
              //border-radius: $border-radius;
            }
          }
        
      }
}
@media (max-width: 768px) {
    .job-profile {
            .content-right 
            {
              width: 100%;
              .job-profile-details {
                .email-scroll-area {
                    padding: 0 1rem;
                }
              }
            }
    }
  }
  @media (max-width: 480px) {
    .job-profile .sidebar .job-profile-sidebar {
      width: 300px;
      height: fit-content;
      background: white;
      margin-left: -20px;
      padding: 10px;
      margin-top: -20px;
    }
    .job-profile .nav-item{
      width: 100%;
      text-align: left;
    }
}
@media (max-width: 359.98px) {
    .job-profile .sidebar .job-profile-sidebar {
      width: 230px;
    }
    .job-profile .job-profile-details .email-detail-header {
      padding: 0 0.5rem;
    }
}
  